import React, { useEffect } from 'react';
import { useState } from 'react';
import './App.css'
import GICard from './/components/GICard/GICard';
import $ from 'jquery'

const API_URL = "https://3gewbsvf9a.execute-api.us-east-2.amazonaws.com/prod";
const ALL_CARDS_URL = API_URL + '/allcards';
const CARD_BY_NAME_URL = API_URL + '/allcards/';

let aniDelay = 0;

function App() {
  const [cardList, setCardList] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [searchVal, setSearchVal] = useState("");



  async function downloadImage(card) {


    let modUrl = card.cardspriteurl.split(".png", 1)[0] + ".png";

    console.log(modUrl);
    const image = await fetch(modUrl)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)

    const link = document.createElement('a')
    link.href = imageURL
    link.download = card.name;
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function getAllCards(url) {
    let response = await fetch(url);
    const data = await response.json();

    // // Download images into folder desktop
    // for (let i = 0; i < data.length; i++) {
    //   downloadImage(data[i]);
    // }

    return data;
  }

  async function getCardByName(url, name) {
    let response = await fetch(url + name);
    const data = await response.json();
    return data;
  }

  const selectCard = (cardName) => {
    console.log(cardName);
    getCardByName(CARD_BY_NAME_URL, cardName).then((result) => {
      console.log(result[0]);

      if (result != null && result.length > 0) {
        setActiveCard(result[0]);
      }
    });

    aniDelay = -100;

    $('#display-card').fadeIn();
  }

  const exitCardInfo = () => {
    setActiveCard(null);
    $('#display-card').fadeOut();
  }

  const searchBarUpdated = (e) => {
    setSearchVal(e.target.value);
    console.log(searchVal);
  }

  useEffect(() => {
    // aniDelay = -100;
  }, [searchVal]);

  useEffect(() => {
    $('#display-card').hide();

    console.log(ALL_CARDS_URL);

    getAllCards(ALL_CARDS_URL).then((result) => {
      console.log(result);
      setCardList(result);
    });

    // aniDelay = -100;
  }, []);

  return (
    <div className='outer-wrap'>
      <div id='display-card' className='display-card-info-wrapper'>
        {/* <div id='display-card' className='display-card-info-wrapper' style={{ visibility: (activeCard != null ? "visible" : "hidden") }}> */}
        <div className='display-card-info-window'>
          <button className='exit-info-btn' onClick={exitCardInfo}>
            BACK
          </button>
          <div className='corner-boxes-wrapper'>
            <div className='corner-box-box'>
              <div className='corner-box' />
            </div>
            <hr className='cb-separator' />
            <div className='corner-box-box'>
              <div className='corner-box' />
            </div>
          </div>

          {
            activeCard != null ?
              (
                <div className='card-info-wrap'>
                  <div className='card-title-box'>
                    <h1>{activeCard.name}</h1>
                  </div>
                  <div className='card-data-wrapper'>
                    {/* <hr></hr> */}
                    <div className='cardpic-wrap'>
                      <img src={activeCard.cardspriteurl.split(".png", 1)[0] + ".png"}></img>
                      {/* <img src={cardBacking}></img> */}
                    </div>
                    <div className='misc-info-wrap'>
                      <h2>Rank <span className='italic'>{activeCard.rank}</span></h2>
                      <hr></hr>
                      <h2>Card <span className='italic'>#{activeCard.number}</span></h2>
                    </div>
                    <div className='misc-info-wrap'>
                      <h2>Type: <span className='type-span'>{activeCard.type}</span></h2>
                    </div>

                    <hr></hr>
                    <div className='card-desc-wrap'>
                      <h2 className='desc-title'>- Description -</h2>
                      <p>{activeCard.description}</p>
                    </div>
                  </div>
                </div>
              )
              :
              (null)
          }

          {/* <div className='card-info-box'>
            <h1>{activeCard.name}</h1>

            <div className=''>
              <img src={activeCard.cardspriteurl}></img>
              <img src={cardBacking}></img>
            </div>
            <p>{activeCard.description}</p>
          </div> */}

          <div className='corner-boxes-wrapper'>
            <div className='corner-box-box'>
              <div className='corner-box' />
            </div>
            <hr className='cb-separator' />
            <div className='corner-box-box'>
              <div className='corner-box' />
            </div>
          </div>
        </div>
      </div>

      <div className='full-page'>
        <div className='title-wrapper'>
          <div className='title-interior-wrap'>
            <div className='title-left title-borders'>
              <p>
                ###
              </p>
            </div>

            <div className='title-middle title-borders'>
              <p>
                GREED ISLAND CARDS DATABASE
              </p>
            </div>

            <div className='title-right title-borders'>
              <p>
                ???
              </p>
            </div>
          </div>
        </div>

        <div className='search-wrap'>
          <hr className='hr-long'></hr>
          <div className='search-design-wrap'>
            <div className='hr-wrap'>
              <hr className='hr-small'></hr>
              <hr className='hr-small'></hr>
              <hr className='hr-small'></hr>
            </div>
            <div className='search-bar-x'>
              <input onChange={searchBarUpdated} value={searchVal} className='search-bar' placeholder='Search...'></input>
              {/* <button className='clear-search'>X</button> */}
            </div>
            <div className='hr-wrap'>
              <hr className='hr-small'></hr>
              <hr className='hr-small'></hr>
              <hr className='hr-small'></hr>
            </div>
          </div>
          <hr className='hr-long hr-bottom'></hr>
        </div>

        <div className='card-holder-wrapper'>
          <div className='cards'>
            {
              cardList.sort(function (a, b) { return a.number - b.number }).filter((c) => c.name.toLowerCase().includes(searchVal.toLowerCase())).map((c) => {
                // aniDelay += 25;
                return <GICard cardData={c} key={c.name} selectFunc={selectCard} delay={aniDelay}></GICard>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
