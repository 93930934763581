import React, { useState } from 'react';
import './gicard.css'
import cardBacking from "../../images/cardback.png"



function GICard(props) {
    const [clicked, setClicked] = useState(false);

    const cardClicked = (e) => {
        props.selectFunc(props.cardData.name);
        setClicked(true);
    }

    const exitCard = (e) => {

    }

    return (
        <div className={'gicard-wrap'}>
            <div className='gicard'>
                <p>{props.cardData.name}</p>

                <div className='rotate-card-wrap' style={{ animationDelay: props.delay + "ms" }} onClick={cardClicked}>


                    <div className='card-image card-front slidecard-left'>
                        <img src={cardBacking}></img>
                    </div>
                    <div className='card-image card-front slidecard-right'>
                        <img src={props.cardData.cardspriteurl.split(".png", 1)[0] + ".png"}></img>
                    </div>
                    <div className='card-image card-back'>
                        <img src={cardBacking}></img>
                    </div>
                </div>

                <div className='number-wrap'>
                    <hr />
                    <p>{props.cardData.number}</p>
                    <hr />
                </div>
            </div>
        </ div>
    )
}

export default GICard